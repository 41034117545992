export enum ErrorSlugs {
  AccessTagRequired = 'access_tag_required',
  AuthenticationError = 'authentication_error',
  DsmNotFoundForTheIteration = 'dsm_not_found_for_the_iteration',
  FormValidationError = 'form_validation_error',
  HeapOutOfBounds = 'heap_out_of_bounds',
  HeapOutOfBaseDsmBounds = 'heap_out_of_base_dsm_bounds',
  IndexOutOfRange = 'index_out_of_range',
  InvalidAccessTagsOrg = 'invalid_access_tags_org',
  InvalidCredentials = 'invalid_credentials',
  InvalidFileType = 'invalid_file_type',
  InvalidLayerAccessTags = 'invalid_layer_access_tags',
  InvalidLayerType = 'invalid_layer_type',
  InvalidParameter = 'invalid_parameter',
  InactiveUser = 'inactive_user',
  LineOutOfBounds = 'line_out_of_bounds',
  NameAlreadyExists = 'name_already_exists',
  ObjectNotFound = 'object_does_not_exist',
  OldPasswordIncorrect = 'password_incorrect',
  OldPasswordRequired = 'current_password_required',
  OnlyHexColorCodesAllowed = 'invalid_color_hex',
  OrgRequired = 'org_required',
  PasswordsNotMatch = 'passwords_not_match',
  PermissionDenied = 'permission_denied',
  SameOrgAccessTagsAndUserGroup = 'invalid_access_tags_and_user_group_in_org',
  SameOrgUsers = 'invalid_users_org',
  SameOrgUsersAndUserGroup = 'invalid_users_and_user_group_in_org',
  SameProjectUserGroupProject = 'same_project_permission_project',
  SameSitePermissionProject = 'same_site_permission_project',
  SameSiteUserGroupProject = 'same_site_user_group_project',
  SpaceNotAllowed = 'space_in_string',
  TokenBlacklisted = 'token_blacklisted',
  TokenError = 'token_error',
  UserGroupRequired = 'user_group_required',
}
