import {
  Button,
  ButtonVariant,
  FormMessage,
  IconIdentifier,
  Input,
  InputGroup,
  ToggleSwitch,
} from '@aus-platform/design-system';
import { Formatter } from 'shared/helpers';
import { MarkArea, MarkAreaDrawingToolType } from './enums';
import { MarkAreaToggleProps } from './types';

export const MarkAreaToggle: React.FC<MarkAreaToggleProps> = ({
  currentMarkedArea,
  onToggleChange,
  drawingHandlers,
  isDrawingToolEnabled,
  workspaceLayerError,
  selectLayerSelectProps,
  headerComponent,
  typeOfLayerComponent,
  drawingType = MarkAreaDrawingToolType.Polygon,
}) => {
  return (
    <InputGroup className="mark-area">
      {headerComponent ?? <div className="mark-area__heading">Mark Area</div>}
      <ToggleSwitch
        leftTitle={Formatter.toTitleCase(MarkArea.Draw, '_')}
        rightTitle={Formatter.toTitleCase(MarkArea.LayerFromWorkspace, '_')}
        leftSelected={currentMarkedArea === MarkArea.Draw}
        onChange={onToggleChange}
      />
      {currentMarkedArea === MarkArea.Draw ? (
        <div className="mark-area__btn">
          {isDrawingToolEnabled ? (
            <>
              <Button
                className="mark-area__btn--exit-draw-polygon"
                variant={ButtonVariant.Primary}
                leftIconIdentifier={
                  drawingType == MarkAreaDrawingToolType.Polygon
                    ? IconIdentifier.Heap
                    : IconIdentifier.Line
                }
                onClick={drawingHandlers.endDrawingHandler}
              >
                Exit Drawing Mode
              </Button>
              <Button
                variant={ButtonVariant.Outline}
                leftIconIdentifier={IconIdentifier.Reset}
                onClick={drawingHandlers.resetDrawingHandler}
                className="mark-area__btn--redo"
              >
                Reset Drawing
              </Button>
            </>
          ) : (
            <Button
              className="mark-area__btn--draw-polygon"
              variant={ButtonVariant.Outline}
              leftIconIdentifier={
                drawingType == MarkAreaDrawingToolType.Polygon
                  ? IconIdentifier.Heap
                  : IconIdentifier.Line
              }
              onClick={drawingHandlers.startDrawingHandler}
              disabled={isDrawingToolEnabled}
            >
              Enter Drawing Mode
            </Button>
          )}
        </div>
      ) : (
        <>
          {typeOfLayerComponent}
          <InputGroup className="mark-area__select-layer">
            <Input.Label>Select Layer</Input.Label>
            <Input.Select
              placeholder="Select Layer from Workspace"
              isDisabled={!!workspaceLayerError}
              {...selectLayerSelectProps}
            />
          </InputGroup>
          {workspaceLayerError && (
            <FormMessage
              className="mark-area__form-message"
              message={workspaceLayerError}
            />
          )}
        </>
      )}
    </InputGroup>
  );
};
